import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = data => (
  <div>
    <header>Sasha Havoc</header>
    <nav>
      <Link activeClassName="active" to="/">
        Home
      </Link>
      {data.menuLinks.map(link => (
        <Link activeClassName="active" partiallyActive="active" to={link.link}>
          {link.name}
        </Link>
      ))}
    </nav>
  </div>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Sasha Havoc`,
}

export default Header
